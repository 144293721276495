<template>
    <div>
        <ReceiptType :url="url" :label="label" :imgUrl="imgUrl"></ReceiptType>
    </div>
</template>

<script>
import ReceiptType from '@/components/ReceiptType.vue';
    export default {
    name: "ExpensesReceipt",
    components: { ReceiptType },
    data(){
        return {
            url:'/api/printexpenditure',
            label:'费用支出单',
            imgUrl:  '/images/PDF/zp_fyzc.pdf'
        }
    }
}
</script>

<style lang="scss" scoped>

</style>