<template>
    <div>
        <ReceiptType :url="url" :imgUrl="imgUrl" :label="label"></ReceiptType>
    </div>
</template>

<script>
import ReceiptType from '@/components/ReceiptType.vue';
export default {
    name: 'FKReceipt',
    components: {
        ReceiptType,
    },
    data() {
        return {
            url:'/api/printfk',
            label: '付款单',
            imgUrl: '/images/PDF/zp_fk.pdf'
        }
    }
}
</script>

<style lang="scss" scoped></style>