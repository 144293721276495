import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import SKReceipt from '@/views/receipts/SKReceipt.vue'//收款
import FKReceipt from '@/views/receipts/FKReceipt.vue'//付款
import PrepaymentsReceipt from '@/views/receipts/PrepaymentsReceipt.vue'//预支付
import ExpensesReceipt from '@/views/receipts/ExpensesReceipt.vue'//费用支出
import OtherIncomeReceipt from '@/views/receipts/OtherIncomeReceipt.vue'//其他收入
import ProcurementReceipt from '@/views/receipts/ProcurementReceipt.vue'//其他收入
import ProcurementbackReceipt from '@/views/receipts/ProcurementbackReceipt.vue'
import ClientReceipt from '@/views/receipts/ClientReceipt.vue'//其他收入
import LkClientReceipt from '@/views/receipts/LkClientReceipt.vue'
import LkGoodsReceipt from '@/views/receipts/LkGoodsReceipt.vue'

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        component: Home,
        meta:{
            routerName:'工作台',
        },
        children: [
            {
                path: 'skreceipt',
                component: SKReceipt,
                meta:{
                    routerName:'导出收款单',
                },
            },
            {
                path: 'fkreceipt',
                component: FKReceipt,
                meta:{
                    routerName:'导出付款单',
                },
            },
            {
                path: 'prepaymentsreceipt',
                component: PrepaymentsReceipt,
                meta:{
                    routerName:'导出预付款单',
                },
            },
            {
                path: 'expensesreceipt',
                component: ExpensesReceipt,
                meta:{
                    routerName:'导出费用支出',
                },
            },
            {
                path: 'otherincomereceipt',
                component: OtherIncomeReceipt,
                meta:{
                    routerName:'导出其他收入',
                },
            },
            {
                path: 'procurementreceipt',
                component: ProcurementReceipt,
                meta:{
                    routerName:'导出采购单',
                },
            },
            {
                path: 'procurementbackreceipt',
                component: ProcurementbackReceipt,
                meta:{
                    routerName:'导出采购退货单',
                },
            },
            {
                path: 'clientreceipt',
                component: ClientReceipt,
                meta:{
                    routerName:'导出客户档案',
                },
            },
            {
                path: 'lkclientreceipt',
                component: LkClientReceipt,
                meta:{
                    routerName:'导出连凯客户档案',
                },
            },
            {
                path: 'lkgoodsreceipt',
                component: LkGoodsReceipt,
                meta:{
                    routerName:'导出连凯商品档案',
                },
            }
            
        ],
    },
]

const router = new VueRouter({
    routes,
})
// router.beforeEach((to,from,next)=>{
//     store.commit('setHistoryRouter',{routerName:to.meta.routerName,routerPath:to.path})
//     next()
// })
export default router