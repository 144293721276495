<template>
    <div>
        <ReceiptType :url="url" :label="label" :imgUrl="imgUrl"></ReceiptType>
    </div>
</template>

<script>
import ReceiptType from '@/components/ReceiptType.vue';
export default {
    name: 'OtherIncomeReceipt',
    components: {
        ReceiptType
    },
    data() {
        return {
            url: '/api/printotherincome',
            label: '其他收入',
            imgUrl:  '/images/PDF/zp_qtsr.pdf'
        }
    }
}
</script>

<style lang="scss" scoped></style>