import {post,get} from './request'
import dateFormate from '@/utlis/dateFormate'
// 导出舟谱
export const printReceipt =function(url,id,cookie,dateStart='',dateEnd=''){
    return get(url,{
        params:{
           id,cookie,dateStart,dateEnd
        }
    })
}
// 导出连凯单据
export const printLkReceipt =function(url,filePath){
    return get(url,{
        params:{
            filePath
        }
    })
}
export const printLkGoodsReceipt =function(url,filePath){
    return get(url,{
        params:{
            filePath
        }
    })
}
export const printsk =function(id,cookie,dateStart,dateEnd){
    return get('/api/printsk',{
        params:{
           id,cookie,dateStart,dateEnd
        }
    })
}
export const printfk =function(id,cookie,dateStart,dateEnd){
    return get('/api/printfk',{
        params:{
           id,cookie,dateStart,dateEnd
        }
    })
}
export const printprepayments =function(id,cookie,dateStart,dateEnd){
    return get('/api/printprepayments',{
        params:{
           id,cookie,dateStart,dateEnd
        }
    })
}
export const printotherincome =function(id,cookie,dateStart,dateEnd){
    return get('/api/printotherincome',{
        params:{
           id,cookie,dateStart,dateEnd
        }
    })
}
export const printprocurement =function(id,cookie,dateStart,dateEnd){
    return get('/api/printprocurement',{
        params:{
           id,cookie,dateStart,dateEnd
        }
    })
}
export const printprocurementback =function(id,cookie,dateStart,dateEnd){
    return get('/api/printprocurementback',{
        params:{
           id,cookie,dateStart,dateEnd
        }
    })
}
export const printclient =function(id,cookie){
    return get('/api/printclient',{
        params:{
           id,cookie
        }
    })
}
export const printexpenditure =function(id,cookie,dateStart,dateEnd){
    return get('/api/printexpenditure',{
        params:{
           id,cookie,dateStart,dateEnd
        }
    })
}
