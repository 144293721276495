<template>
    <div >
        <LkReceipt :url="url" :type="type" :imgUrl="imgUrl"></LkReceipt>
    </div>
</template>

<script>
import LkReceipt from '@/components/LkReceipt'
export default {
    name: 'LkClientReceipt',
    data() {
        return {
            type:'lkg',
            url:'/api/printlkgoods',
            imgUrl:'/images/PDF/lk_g.pdf'
        }
    },
    components: {LkReceipt },
}
</script>

<style lang="scss" scoped>
</style>