import Vue from 'vue'
import Loading from './Loading.vue'
export const loading = function (loadingWidth,loadingHeight) {
    // 子类Vue构造器
    var VueChild = Vue.extend({
        render: h => h(Loading,{
            props:{
                loadingWidth,
                loadingHeight
            }
        }),
    })
    const vmchild = new VueChild().$mount()
    document.body.appendChild(vmchild.$el)
    var component = vmchild.$children[0]

    component.remove = function () {
        document.body.removeChild(vmchild.$el)
        vmchild.$destroy()
    }
    return component
}