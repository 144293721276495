<template>
    <el-container class="container">
        <el-header class="layout-header"><img src="../assets/images/logo.svg"></el-header>
        <el-container class="layout-container">
            <el-aside width="70px" class="mod-aside">
                <el-popover placement="right-start"  trigger="hover">
                    <div class="receipt-box">
                        <div class="zp-receipt receipt-type">
                            <h3>舟谱数据</h3>
                            <li v-for="(item, index) in routers[0].zpReceipt " :key="item.id" class="receipt" @click="pushRoute(item)">
                                {{ item.name }}
                            </li>
                        </div>
                        <div class="lk-receipt receipt-type">
                            <h3>连凯数据</h3>
                            <li v-for="(item, index) in routers[1].lkReceipt " :key="item.id" class="receipt" @click="pushRoute(item)">
                                {{ item.name }}
                            </li>
                        </div>
                    </div>
                    <el-button slot="reference" style="background-color: transparent;border:none"><i class="el-icon-printer"
                            style="font-size:24px;color:#f2f2f2"></i></el-button>
                </el-popover>
            </el-aside>
            <el-main class="mod-main">
                <div class="cell-nav">
                    <li v-for="(item, index) in historyRouter" :key="index" class="li-item"
                        :class="index === currentIndex ? 'current-route' : 'old-route'">
                        <span style="padding:0 5px" @click="skipRoute(item, index)">{{ item.routerName }}</span>
                        <span class="icon-highlight_remove" @click="deleteRoute(item, index)"></span>
                    </li>
                </div>
                <!-- <keep-alive> <router-view></router-view></keep-alive> -->
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import { mapState, mapMutations,mapActions } from 'vuex'
export default {
    name: 'Home',
    data() {
        return {
            routers: [
                {
                    zpReceipt: [
                        {
                            id: 0,
                            path: "/skreceipt",
                            name: '导出收款单',
                        },
                        {
                            id: 1,
                            path: "/fkreceipt",
                            name: "导出付款单",
                        },
                        {
                            id: 2,
                            path: "/prepaymentsreceipt",
                            name: "导出预付款单"
                        },
                        {
                            id: 3,
                            path: "/expensesreceipt",
                            name: "导出费用支出单"
                        },
                        {
                            id: 4,
                            path: "/otherincomereceipt",
                            name: "导出其他收入"
                        },
                        {
                            id: 5,
                            path: "/procurementreceipt",
                            name: "导出采购单"
                        },
                        {
                            id: 6,
                            path: "/procurementbackreceipt",
                            name: "导出采购退货单"
                        },
                        {
                            id: 7,
                            path: "/clientreceipt",
                            name: "导出客户档案"
                        },
                    ]
                },
                {
                    lkReceipt: [
                        {
                            id: 7,
                            path: "/lkclientreceipt",
                            name: "导出连凯客户档案"
                        },
                        {
                            id: 8,
                            path: "/lkgoodsreceipt",
                            name: "导出连凯商品档案"
                        },
                    ]
                }
            ],
            currentIndex: 0,
        }
    },
    computed: {
        ...mapState({
            historyRouter: state => state.historyRouter,
            selectedRoute: state => state.selectedRoute,
        })
    },
    methods: {
        ...mapMutations(['deleteHistoryRouter', 'setHistoryRouter', 'setSelectedRoute']),
        ...mapActions(['shiftHistoryRouter']),
        deleteRoute(item, index) {
            if (index == this.currentIndex) {
                if (index == 0 && this.historyRouter.length > 1) {
                    this.deleteHistoryRouter(index)
                    this.currentIndex = index + 1
                    this.$router.replace({ path: this.historyRouter[index + 1].routerPath })
                } else if (index != 0) {
                    this.deleteHistoryRouter(index)
                    this.currentIndex = index - 1
                    this.$router.replace({ path: this.historyRouter[index - 1].routerPath })
                }
            } else if (index < this.currentIndex) {
                this.currentIndex--
                this.deleteHistoryRouter(index)

            } else {
                this.deleteHistoryRouter(index)
            }
        },
        pushRoute(item) {
            this.currentIndex++
            // this.
            this.setHistoryRouter({ routerName: item.name, routerPath: item.path })
            // thi.$store.dip
            this.$router.replace({ path: item.path }).catch(err => console.log())
        },
        skipRoute(item, index) {
            this.currentIndex = index
            this.$router.replace({ path: item.routerPath }).catch(err => console.log())
        }
    },
}
</script>

<style lang="scss" scoped>
.receipt-box {
    padding-left: 10px;
    display: flex;
    .receipt-type{
        padding:0 30px;
        border-right:2px dotted #888;
        width:250px;
        &:last-of-type{
            border:none;
        }
        h3{
            padding: 8px 0;
            font-size: 18px;
            font-weight: bold;
            color:#222;
        }
        li{
            padding:8px 0;
        }
    }

    .receipt {
        padding: 5px 0;
        margin-bottom: 5px;

        &:hover {
            color: orangered;
        }
    }
}

.container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .layout-header {
        padding:20px;
        width: 100%;
        height: 58px;
        background-color: #2B2D35;
        color: rgb(221, 221, 221);
        img{
            height: 30px;
            width: 30px;
        }
    }
}

.layout-container {
    width: 100%;
    height: calc(100vh - 58px);

    .mod-aside {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 4.375rem;
        background-color: #2B2D35;
        color: rgb(221, 221, 221);

    }

    .mod-main {
        padding: 0;
        .cell-nav {
            display: flex;
            align-items: center;
            height: 28px;
            width: 100%;
            background-color: #525660;

            .li-item {
                padding: 0 8px;
                line-height: 28px;
                font-size: 14px;
            }

            .old-route {
                color: #fff;
            }

            .current-route {
                background-color: #fff;
            }
        }
    }
}
</style>
  