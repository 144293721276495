<template>
    <div>
        <ReceiptType :url="url" :label="label" :imgUrl="imgUrl"></ReceiptType>
    </div>
</template>

<script>
import ReceiptType from '@/components/ReceiptType.vue';

export default {
    name: "PrepaymentsReceipt",
    components: { ReceiptType },
    data() {
        return {
            url: '/api/printprepayments',
            label: '预付款单',
            imgUrl:  '/images/PDF/zp_yfk.pdf'
        }
    }
}
</script>

<style lang="scss" scoped></style>