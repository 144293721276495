import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import echarts from "echarts";
import '@/assets/style.css'
import {loading} from '@/api/loading/index.js'
// main.js
import htmlToPdf from '@/utlis/htmlToPdf'



Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(htmlToPdf)
Vue.prototype.$loading = loading

const vm = new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
export default vm
