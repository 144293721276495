<template>
    <div>
        <ReceiptType :isNotShowDatePicker="true" :url="url" :label="label" :imgUrl="imgUrl"></ReceiptType>
    </div>
</template>

<script>
import ReceiptType from '@/components/ReceiptType.vue';
    export default {
        name:'ClientReceipt',
        components:{
            ReceiptType
        },
        data(){
            return {
                url:'/api/printclient',
                label:'客户档案',
                imgUrl:  '/images/PDF/zp_khda.pdf'
                // tutorialSrc:require
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>