import { render, staticRenderFns } from "./OtherIncomeReceipt.vue?vue&type=template&id=5d50b810&scoped=true&"
import script from "./OtherIncomeReceipt.vue?vue&type=script&lang=js&"
export * from "./OtherIncomeReceipt.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d50b810",
  null
  
)

export default component.exports