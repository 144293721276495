import Vue from 'vue'
import Vuex from 'vuex' 
Vue.use(Vuex)
const store = new Vuex.Store({
    state:{
        historyRouter:[
            {routerName: '工作台', routerPath: '/'}
        ],
        id:'',
        cookie:'',
        date:[]
    },
    getters:{
        lenHistoryRouter(state){
            return state.historyRouter.length >=8
        }
    },
    mutations:{
        setHistoryRouter(state,playload){
            state.historyRouter.push(playload)
        },
        deleteHistoryRouter(state,playload){
            state.historyRouter.splice(playload,1)
        },
        setId(state,playload){
            state.id = playload
        },
        setCookie(state,playload){
            state.cookie = playload
        },
        setDate(state,playload){
            state.date = playload
        }
    },
    actions:{
        shiftHistoryRouter({commit,getters}) {
           getters.lenHistoryRouter ? commit('deleteHistoryRouter') : commit('setHistoryRouter')
        },
    }
}) 
export default store