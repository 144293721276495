<template>
    <div class="layout-lk-input">
        <div class="mod-lk">
            <span> 文件地址：</span>
            <el-input placeholder="输入您的文件地址" v-model="filePath" clearable>
            </el-input>
            <JsonExcel class="export-excel-wrapper" :fetch="exportToExcel" :fields="fields" :name="fileName + '.xls'">
                <el-button type="primary">导出为excel文件</el-button>
            </JsonExcel>
            <a :href="imgUrl" target="_blank" style="color:#fff"><el-button type="primary" class="btn-show">查看教程</el-button></a>
        </div>
    </div>
</template>

<script>
import JsonExcel from 'vue-json-excel'
import { printLkReceipt } from '@/axios/index'
export default {
    name: 'LkReceipt',
    data() {
        return {
            filePath: '',
            fields: null,
            fileName: '',
            btnValue: '查看教程',
        }
    },
    props: {
        type: {
            type: String,
        },
        url: {
            type: String,
        },
        imgUrl: {
            type: String,
        }
    },
    components: { JsonExcel},
    methods: {
        async exportToExcel() {
            console.log('导出')
            const res = await printLkReceipt(this.url, JSON.stringify(this.filePath))
            let data_excel = res.data.data_excel
            this.fields = res.data.title_excel
            this.fileName = res.data.fileName
            if (data_excel.length == 0) {
                alert('未查询到数据')
            }
            return data_excel
        },
    }
}
</script>

<style lang="scss" scoped>
.layout-lk-input {
    width: 100%;
    // height:inherit;
    overflow: hidden;

    .mod-lk {
        padding: 10px;
        display: flex;
        align-items: center;

        span {
            display: flex;
            justify-content: flex-end;
            width: 100px;
            color: #606266;
        }

        ::v-deep {
            .el-input {
                margin-right: 20px;
                width: 300px;

                .el-input__inner {
                    border: none;
                    border-radius: 0;
                    border-bottom: 1px solid #222;
                }
            }
        }

        .btn-show {
            margin-left: .5rem;
        }

    }
}
</style>