<template>
  <div class="container-searchdocuments">
    <el-form label-width="100px" class="demo-ruleForm">
      <div class="first-row">
        <div class="input-item">
          <el-form-item label="ID:" prop="id" class="el-form-item">
            <div class="input-deep"><el-input v-model="id" clearable></el-input></div>
          </el-form-item>
          <!-- <el-tooltip class="item tooltip" effect="light" content="按照教程输入正确信息" placement="right-start"
            style="margin-left:.5rem;margin-top:8px;">
            <span class="icon-question"></span>
          </el-tooltip> -->
        </div>
        <div class="input-item">
          <el-form-item label="cookie:" prop="cookie" class="el-form-item">
            <el-input v-model="cookie" clearable></el-input>
          </el-form-item>
          <!-- <el-tooltip class="item tooltip" effect="light" content="按照教程输入正确信息" placement="right-start"
            style="margin-left:.5rem;margin-top:8px;">
            <span class="icon-question"></span>
          </el-tooltip> -->
        </div>
        <div class="btn-box">
          <JsonExcel class="export-excel-wrapper" :fetch="startDownLoad" :before-generate="loadingStart"
            :before-finish="loadingEnd" :fields="fields" :name="fileName + '.xls'">
            <el-button type="primary">导出为excel文件</el-button>
          </JsonExcel>
          <a :href="imgUrl" target="_blank" style="color:#fff"><el-button type="primary" class="btn-show">查看教程</el-button></a>
        </div>
      </div>
      <div class="sec-row" v-show="!isNotShowDatePicker">
        <div class="input-time">
          <el-form-item :label="$route.meta.routerName + ':'" prop="daterange" class="el-form-item">
            <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期" style="width:400px">
            </el-date-picker>
          </el-form-item>
          <!-- <el-tooltip class="item tooltip" effect="light" content="请输入您要查询的日期" placement="right-start"
            style="margin-left:.5rem;margin-top:8px;">
            <span class="icon-question"></span>
          </el-tooltip> -->
        </div>
      </div>
      <div class="third-row" style="padding:0 10px;color:crimson">*同一账号导出不同单据，ID、Cookie通用，并在其他单据导出页面自动填入</div>
    </el-form>
  </div>
</template>

<script>
import JsonExcel from 'vue-json-excel'
import { printReceipt } from '@/axios/index'
import { mapState } from 'vuex'
import { dateFormate } from '@/utlis/dateFormate'
export default {
  name: 'ReceiptType',
  props: ['isNotShowDatePicker', 'url', 'label','imgUrl'],
  data() {
    return {
      fields: null,
      json_data: [],
      data_excel: [],
      fileName: '',
    }
  },
  components: { JsonExcel },
  computed: {
    ...mapState({
      historyRouter: state => state.historyRouter,
    }),
    id: {
      get() {
        return this.$store.state.id
      },
      set(newValue) {
        this.$store.commit('setId', newValue)
      }
    },
    cookie: {
      get() {
        return this.$store.state.cookie
      },
      set(newValue) {
        this.$store.commit('setCookie', newValue)
      }
    },
    date: {
      get() {
        return this.$store.state.date
      },
      set(newValue) {
        this.$store.commit('setDate', newValue)
      }
    }
  },
  methods: {
    async startDownLoad() {
      if (this.isNotShowDatePicker) {
        if (!this.id || !this.cookie) {
          alert('请按提示输入参数')
        } else {
          const res = await printReceipt(this.url, this.id, this.cookie)
          let data_excel = res.data.data_excel
          this.fields = res.data.title_excel
          this.fileName = res.data.fileName
          if (data_excel.length == 0) {
            alert('未查询到数据')
          }
          return data_excel
        }
      } else {
        if (!this.id || !this.cookie || !this.date.length) {
          alert('请按提示输入参数')
        } else {
          const res = await printReceipt(this.url, this.id, this.cookie, dateFormate(this.date[0]), dateFormate(this.date[1]))
          let data_excel = res.data.data_excel
          this.fields = res.data.title_excel
          this.fileName = res.data.fileName
          if (data_excel.length == 0) {
            alert('未查询到数据')
          }
          return data_excel
        }
      }
    },
    loadingStart() {
      console.log("下载开始");
    },
    loadingEnd() {
      console.log("下载完成");
    },
  },
}
</script>
  
<style lang="scss">
.container-searchdocuments {
  position: relative;
  padding: 10px;
  height: calc(100vh - 100px);
  width: 100%;
  background-color: #fff;
  overflow: auto;

  .demo-ruleForm {
    display: flex;
    flex-direction: column;
    width: 100%;

    .first-row {
      position: relative;
      display: flex;
      flex-wrap: wrap;

      .input-item {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        min-width: 300px;
        max-width: calc(100% / 4);

        .el-form-item {
          margin-bottom: 0;

          .el-input__inner {
            border: none;
            border-bottom: 1px solid #666;
            border-radius: 0;
            outline: none;
          }
        }
      }

      .btn-box {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        left: 700px;

        button {
          margin-right: 10px;
          border-radius: 5px;
        }
      }
    }

    .sec-row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .input-time {
        margin-bottom: 10px;
        display: flex;

        .el-input__inner {
          border: none;
          border-bottom: 1px solid #666;
          border-radius: 0;
          outline: none;
        }
      }
    }

  }
}
</style>