<template>
    <div class="loading-inner-box" :style="{width:loadingWidth,height:loadingHeight}">
        <div class="loading"></div>
    </div>
</template>

<script>
export default {
    name: 'Loading',
    // props: ['loadingWidth','loadingHeight'],
    props:{
        loadingWidth:{
            type:String,
        },
        loadingHeight:{
            type:String
        }
    },
    computed:{
        // loadingStyle(){
        //     return {}
        // }
    },
    mounted(){
  
    }
}
</script>

<style lang="scss" scoped>
.loading-inner-box {
    margin-top:-50px;
    margin-left:-50px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 100px;
    // width: 100px;
    background-color: rgba(0, 0, 0, .8);

    .loading {
        height: 30px;
        width: 30px;
        border-radius: 100%;
        border: 2px solid #fff;
        border-top-color: transparent;
        border-left-color: transparent;
        animation: circle infinite 1s linear;
    }

    @keyframes circle {
        0% {
            transform: rotate(0);
        }

        100% {
            transform: rotate(365deg);
        }
    }
}
</style>