<template>
    <div>
        <LkReceipt :url="url" :type="type" :imgUrl="imgUrl"></LkReceipt>
    </div>
</template>

<script>
import LkReceipt from '@/components/LkReceipt'
export default {
    name: 'LkClientReceipt',
    data() {
        return {
            type:'lkc',
            url:'/api/printlkclient',
            imgUrl:'/images/PDF/lk_c.pdf'
        }
    },
    components:{LkReceipt},
}
</script>

<style lang="scss" scoped>
</style>