<template>
  <div id="app">
     <router-view></router-view> 
  </div>
</template>

<script>
export default {
  name: 'App',
  created(){
    if (this.$route.path !== "/")
        this.$router.replace({ path: "/" })
    }

}
</script>

